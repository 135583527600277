<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <CButton
          class="px-0"
          color="link"
          @click="openingTimesCopyTo"
        >
          <span v-if="copySucceeded.openingTimesCopyTo"><CIcon name="cil-check-alt" class="mr-1" />Applied!</span>
          <span v-else><CIcon name="cil-copy" class="mr-1" />Apply Monday to all days</span>
        </CButton>
      </div>
      <div class="col-sm-3 d-none d-sm-block px-sm-0"><h5 class="mb-0">Open from</h5></div>
      <div class="col-sm-3 d-none d-sm-block"><h5 class="mb-0">Open till</h5></div>
    </div>

    <restaurant-times @isValid="(value) => isValidOpeningTime.mo = value" day='Monday' :time.sync="$v.form.open_monday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.tu = value" day='Tuesday' :time.sync="$v.form.open_tuesday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.we = value" day='Wednesday' :time.sync="$v.form.open_wednesday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.th = value" day='Thursday' :time.sync="$v.form.open_thursday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.fr = value" day='Friday' :time.sync="$v.form.open_friday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.sa = value" day='Saturday' :time.sync="$v.form.open_saturday.$model" />
    <restaurant-times @isValid="(value) => isValidOpeningTime.su = value" day='Sunday' :time.sync="$v.form.open_sunday.$model" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"

export default {
  name: 'RestaurantWizardStep3',
  components: {
    RestaurantTimes: () => import('@/components/RestaurantTimes'),
  },
  data() {
    return {
      form: { },
      isValidOpeningTime: {'mo': true, 'tu': true, 'we': true, 'th': true, 'fr': true, 'sa': true, 'su': true, },
      copySucceeded: {
        openingTimesCopyTo: null,
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      open_monday: {},
      open_tuesday: {},
      open_wednesday: {},
      open_thursday: {},
      open_friday: {},
      open_saturday: {},
      open_sunday: {},
    }
  },

  async mounted() {
    this.form = this.getForm();

    this.$nextTick(function () {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return Object.values(this.isValidOpeningTime).every(Boolean); },
  },

  methods: {
    getForm() {
      const storedForm = localStorage.getItem('pending-restaurant');

      if (storedForm) {
        var form = JSON.parse(storedForm);

        form.open_monday = form.open_monday || "";
        form.open_tuesday = form.open_tuesday || "";
        form.open_wednesday = form.open_wednesday || "";
        form.open_thursday = form.open_thursday || "";
        form.open_friday = form.open_friday || "";
        form.open_saturday = form.open_saturday || "";
        form.open_sunday = form.open_sunday || "";

        return form;
      } else {
        this.$emit("reset");
      }
    },

    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("previous", true);
    },

    openingTimesCopyTo() {
      this.form.open_tuesday =
      this.form.open_wednesday =
      this.form.open_thursday =
      this.form.open_friday =
      this.form.open_saturday =
      this.form.open_sunday = this.form.open_monday;

      this.copySucceeded.openingTimesCopyTo = true;
      setTimeout(() => this.copySucceeded.openingTimesCopyTo = false, 5000);
    },
  }
}
</script>
